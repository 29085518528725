export default(state,action)=>{
    switch (action.payload){
        case 'en':
            return Object.assign({},state,{language:action.type})
        case 'zh':
            return Object.assign({},state,{language:action.type})
        case 'logo':
            return Object.assign({}, state,{logo: action.type})
        case 'background':
            return Object.assign({}, state,{background: action.type})
        case 'fresh': 
            return Object.assign({}, state, {fresh: action.type})
        case 'positionList':
            return Object.assign({}, state, {positionList: action.type})
        default:
        return state
    }
}