import Ajax from "./Fecth"

export default {
    login(params){  // 登录
        return Ajax.ajaxs("/login", params);
    },
    logout(){   // 退出登录
        return Ajax.ajaxs("/logout");
    },
    index: {  // 首页
        getUserNum() { // 设备总数，在线设备，用户总数，激活用户
            return Ajax.ajaxs('/index/getUserNum')
        },
        getDeviceStatus() { // 查询设备状态数量
            return Ajax.ajaxs('/index/getDeviceStatus')
        },
        getDeviceViolateStatus() { // 查询设备违规状态
            return Ajax.ajaxs('/index/getDeviceViolateStatus')
        },
        getStrategyInfo() { // 查询策略执行情况
            return Ajax.ajaxs('/index/getStrategyInfo')
        },
        getAppsInfo() { // 查询应用执行情况
            return Ajax.ajaxs('/index/getAppsInfo')
        },
        getDevicePosition() { // 查询最后次设备位置
            return Ajax.ajaxs('/index/getDevicePosition')
        },
        getLastCommand() { // 查询最后次命令
            return Ajax.ajaxs('/index/getLastCommand')
        },
        getCPU() { // CPU信息
            return Ajax.ajaxs('/index/cpu')
        },
        getMemory() { // 内存信息
            return Ajax.ajaxs('/index/memory')
        },
        getCommonApps() { // 孩子常用应有
            return Ajax.ajaxs('/index/getCommonApps')
        },
    },
    msg: {
        countNoticeMessage(params){   // 未读消息个数
            return Ajax.ajaxs("/plat/countNoticeMessage", params);
        },
        findNoticeMessageList(params){   // 消息列表
            return Ajax.ajaxs("/plat/findNoticeMessageList", params);
        },
        updateNoReadMsgList(params){   // 平台未读消息设为已读
            return Ajax.ajaxs("/plat/updateNoReadMsgList", params);
        },
    },
    user: {
        existName(params){   // 判断用户账号是否已注册
            return Ajax.ajaxs("/user/existName", params);
        },
        existPhone(params){  // 判断用户手机号是否已注册-孩子
            return Ajax.ajaxs("/user/existPhone", params);
        },
        checkLoginPhone(params){  // 判断用户手机号是否已注册-家长
            return Ajax.ajaxs("/user/checkLoginPhone", params);
        },
        list(params){    // 获取用户列表
            return Ajax.ajaxs("/user/list", params);
        },
        getUserByPositionIds(params){  // 根据指定职位ID获取用户id
            return Ajax.ajaxs("/user/getUserByPositionIds", params);
        },
        add(params){  // 新增用户
            return Ajax.ajaxs("/user/add", params);
        },
        addUserList(params){  // 批量新增用户
            return Ajax.ajaxs("/user/addUserList", params);
        },
        update(params){  // 修改用户
            return Ajax.ajaxs("/user/update", params);
        },
        delete(params){   // 删除用户
            return Ajax.ajaxs("/user/delete", params);
        },
        exportExcel(params){   // 导出用户数据
            return Ajax.ajaxs("/user/exportExcel", params, 'GET');
        },
        existNameForEqu(params){  // 批量导入设备时检查账号是否存在
            return Ajax.ajaxs("/user/existNameForEqu", params);
        },
        listByUserId(params){   // 获取用户详情里的文件/策略/应用
            return Ajax.ajaxs("/user/listByUserId", params)
        },
        moveUsers(params){  // 移动用户到其他用户组
            return Ajax.ajaxs("/user/moveUsers", params)
        },
        existCardNo(params){  // 验证身份证号码是否存在
            return Ajax.ajaxs("/user/existCardNo", params)
        },
        existImei(params){   // 验证IMEI号是否存在
            return Ajax.ajaxs("/user/existImei", params)
        },
        getStudentListByUserId(params){   // 根据userId 查询孩子
            return Ajax.ajaxs("/user/getStudentListByUserId", params)
        }
    },
    tag: {
        list(params){ // 获取标签列表
            return Ajax.ajaxs("/tag/list", params)
        },
        tagAddUser(params){   // 添加用户到标签
            return Ajax.ajaxs("/tag/tagAddUser", params)
        },
        add(params){   //  新增标签
            return Ajax.ajaxs("/tag/add", params)
        },
        update(params){  // 修改标签
            return Ajax.ajaxs("/tag/update", params)
        },
        existName(params){    // 判断标签名是否已经存在
            return Ajax.ajaxs("/tag/existName", params)
        },
        delete(params){   // 删除标签
            return Ajax.ajaxs("/tag/delete", params)
        },
        findListUsers(params){   // 根据id查询用户列表内容
            return Ajax.ajaxs("/tag/findListUsers", params)
        }
    },
    admits: {
        find(){   // 获取设备准入条件
            return Ajax.ajaxs("/admits/find")
        },
        add(params){    // 设置/修改设备准入条件
            return Ajax.ajaxs("/admits/add", params)
        },
        list(params){   // 获取设备准入信息
            return Ajax.ajaxs("/admits/list", params)
        },
        addTermList(params){   // 批量导入设备准入信息
            return Ajax.ajaxs("/admits/addTermList", params)
        },
        exportExcel(params){  // 导出设备准入信息
            return Ajax.ajaxs("/admits/exportExcel", params, 'get')
        }
    },
    apps: {
        add(params){   // 添加应用
            return Ajax.uploadFile("/apps/add", params)
        },
        list(params){  // 获取应用列表
            return Ajax.ajaxs("/apps/list", params)
        },
        update(params){   // 修改应用信息
            return Ajax.ajaxs("/apps/update", params)
        },
        delete(params){
            return Ajax.ajaxs("/apps/delete", params)
        },
        getAppTypeList(params){
            return Ajax.ajaxs("/apps/getAppTypeList", params)
        }
    },
    file: {   
        list(params){  // 获取文件列表
            return Ajax.ajaxs("/file/list", params)
        },
        add(params){   // 上传文件
            return Ajax.uploadFile("/file/add", params)
        },
        delete(params){  // 删除文件
            return Ajax.ajaxs("/file/delete", params)
        },
        onlyUpload(params){  // 只上传文件
            return Ajax.uploadFile("/file/onlyUpload", params)
        }
    },
    menu: {
        queryButtonList(params){   // 根据菜单访问路径获取下级按钮
            return Ajax.ajaxs("/menu/queryButtonList", params)
        },
        queryMenuList(params){  // 根据菜单访问路径获取下级菜单
            return Ajax.ajaxs("/menu/queryMenuList", params)
        },
        add(params){  // 新增菜单
            return Ajax.ajaxs("/menu/add", params)
        },
        list(params){  // 根据管理员权限获取菜单列表
            return Ajax.ajaxs("/menu/list", params)
        },
        update(params){  // 修改菜单
            return Ajax.ajaxs("/menu/update", params)
        },
        plist(params){   // 获取父角色的菜单列表
            return Ajax.ajaxs("/menu/plist", params)
        },
        delete(params){  // 删除菜单
            return Ajax.ajaxs("/menu/delete", params)
        },
        getMenuList(params){  // 获取所有菜单列表
            return Ajax.ajaxs("/menu/getMenuList", params)
        }
    },
    role: {
        list(params){   // 获取角色列表
            return Ajax.ajaxs("/role/list", params)
        },
        add(params){   // 新增角色
            return Ajax.ajaxs("/role/add", params)
        },
        queryRoleMenu(params){   // 获取角色的菜单权限列表
            return Ajax.ajaxs("/role/queryRoleMenu", params)
        },
        saveRoleMenu(params){   // 设置/修改角色的菜单权限列表
            return Ajax.ajaxs("/role/saveRoleMenu", params)
        },
        update(params){   // 修改角色
            return Ajax.ajaxs("/role/update", params)
        },
        delete(params){  // 删除角色
            return Ajax.ajaxs("/role/delete", params)
        }
    },
    admin: {
        add(params){   // 新增管理员
            return Ajax.ajaxs("/admin/add", params)
        },
        existName(params){   // 判断管理员账号是否已存在
            return Ajax.ajaxs("/admin/existName", params)
        },
        list(params){   // 获取管理员列表
            return Ajax.ajaxs("/admin/list", params)
        },
        update(params){  //  修改管理员信息
            return Ajax.ajaxs("/admin/update", params)
        },
        delete(params){  //  删除管理员信息
            return Ajax.ajaxs("/admin/delete", params)
        },
        findAdmin(params){  //  查询管理员信息
            return Ajax.ajaxs("/admin/findAdmin", params)
        },
        updatePassword(params){  //  修改管理员密码
            return Ajax.ajaxs("/admin/updatePassword", params)
        },
        updateColDevice(params){  // 配置设备管理的字段显示
            return Ajax.ajaxs("/admin/updateColDevice", params)
        },
        getById(params){
            return Ajax.ajaxs("/admin/getById", params, 'GET')
        },
        verifyCode(params){  // 获取图片验证码
            return Ajax.ajaxs("/admin/verifyCode", params, 'GET')
        }
    },
    apn: {
        add(params){   // 添加APN配置
            return Ajax.ajaxs("/apn/add", params)
        },
        update(params){   // 修改APN配置
            return Ajax.ajaxs("/apn/update", params)
        },
        list(params){   // 获取APN配置列表
            return Ajax.ajaxs("/apn/list", params)
        }, 
        delete(params){  // 删除APN配置
            return Ajax.ajaxs("/apn/delete", params)
        }
    },
    vpn: {
        add(params){   // 添加VPN配置
            return Ajax.ajaxs("/vpn/add", params)
        },
        update(params){   // 修改VPN配置
            return Ajax.ajaxs("/vpn/update", params)
        },
        list(params){   // 获取VPN配置列表
            return Ajax.ajaxs("/vpn/list", params)
        },
        delete(params){  // 删除VPN配置
            return Ajax.ajaxs("/vpn/delete", params)
        }
    },
    wifi: {
        add(params){   // 添加WIFI配置
            return Ajax.ajaxs("/wifi/add", params)
        },
        update(params){   // 修改WIFI配置
            return Ajax.ajaxs("/wifi/update", params)
        },
        list(params){   // 获取WIFI配置列表
            return Ajax.ajaxs("/wifi/list", params)
        }, 
        delete(params){  // 删除WIFI配置
            return Ajax.ajaxs("/wifi/delete", params)
        }
    },
    plat: {
        findClientInfo(){   // 获取关于我们
            return Ajax.ajaxs("/plat/findClientInfo")
        },
        updateClientInfo(params){  // 修改关于我们
            return Ajax.ajaxs("/plat/updateClientInfo", params)
        },
        findClientManagement(){  // 获取客户端基本设置
            return Ajax.ajaxs("/plat/findClientManagement")
        },
        updateClientManagement(params){  // 修改客户端基本设置
            return Ajax.ajaxs("/plat/updateClientManagement", params)
        },
        addMobileModel(params){   // 添加手机机型
            return Ajax.ajaxs("/plat/addMobileModel", params)
        },
        findMobileModelList(params){ // 获取手机机型列表
            return Ajax.ajaxs("/plat/findMobileModelList", params)
        },
        updateMobileModel(params){  // 修改手机机型
            return Ajax.ajaxs("/plat/updateMobileModel", params)
        },
        delMobileModel(params){  // 删除手机机型
            return Ajax.ajaxs("/plat/delMobileModel", params)
        },
        addMobileSystem(params){   // 添加手机操作系统版本
            return Ajax.ajaxs("/plat/addMobileSystem", params)
        },
        findMobileSystemList(params){  // 获取手机操作系统列表
            return Ajax.ajaxs("/plat/findMobileSystemList", params)
        },
        updateMobileSystem(params){   // 修改手机操作系统版本
            return Ajax.ajaxs("/plat/updateMobileSystem", params)
        },
        delMobileSystem(params){   // 删除手机操作系统版本
            return Ajax.ajaxs("/plat/delMobileSystem", params)
        },
        addApk(params){   // 上传新版客户端APP
            return Ajax.uploadFile("/plat/addApk", params)
        },
        findApkList(params){  // 获取客户端版本列表
            return Ajax.ajaxs("/plat/findApkList", params)
        },
        findEmail(){  // 获取激活邮件模板
            return Ajax.ajaxs("/plat/findEmail")
        },
        updateEmail(params){  // 修改激活邮件模板
            return Ajax.ajaxs("/plat/updateEmail", params)
        },
        findLogo(){   // 获取平台LOGO和背景图
            return Ajax.ajaxs("/plat/findLogo")
        },
        uploadLogo(params){   // 上传平台LOGO
            return Ajax.uploadFile("/plat/uploadLogo", params)
        },
        uploadBg(params){   // 上传平台背景图
            return Ajax.uploadFile("/plat/uploadBg", params)
        },
        delApk(params){
            return Ajax.ajaxs("/plat/delApk", params)
        },
        findParentAppList(params){   // 查询家长端apk列表
            return Ajax.ajaxs("/plat/findParentAppList", params)
        },
        addParentApk(params){   // 新增家长端apk
            return Ajax.uploadFile("/plat/addParentApk", params)
        },
        delParentApk(params){   // 删除家长端apk
            return Ajax.ajaxs("/plat/delParentApk", params)
        },
        updateEquipmentRenewal(params){   // 修改客户端APP
            return Ajax.ajaxs("/plat/updateEquipmentRenewal", params)
        },
    },
    lic: {
        add(params){   // 上传license
            return Ajax.uploadFile("/lic/add", params)
        },
        get(){   // 获取license信息
            return Ajax.ajaxs("/lic/get")
        }
    },
    blackwhite: {
        list(params){
            return Ajax.ajaxs("/blackwhite/list", params)
        },
        add(params){
            return Ajax.ajaxs("/blackwhite/add", params)
        },
        update(params){
            return Ajax.ajaxs("/blackwhite/update", params)
        },
        delete(params){
            return Ajax.ajaxs("/blackwhite/delete", params)
        }
    },
    strategy: {
        list(params){
            return Ajax.ajaxs("/strategy/list", params)
        },
        add(params){
            return Ajax.ajaxs("/strategy/add", params)
        },
        update(params){
            return Ajax.ajaxs("/strategy/update", params)
        },
        delete(params){
            return Ajax.ajaxs("/strategy/delete", params)
        },
        listStrategysByDeviceId(params){ // 设备详情-策略
            return Ajax.ajaxs("/strategy/listStrategysByDeviceId", params)
        },
        existStrategyName(params){  // 验证策略名称是否重复
            return Ajax.ajaxs("/strategy/existStrategyName", params)
        }
    },
    issue: {
        issuedStrategyByEqu(params){   // 按设备下发策略
            return Ajax.ajaxs("/issue/issuedStrategyByEqu", params)
        },
        issuedFileByEqu(params){  // 按设备下发文件
            return Ajax.ajaxs("/issue/issuedFileByEqu", params)
        },
        issuedAppByEqu(params){  // 按设备下发应用
            return Ajax.ajaxs("/issue/issuedAppByEqu", params)
        },
        issuedStrategy(params){   // 按用户下发策略
            return Ajax.ajaxs("/issue/issuedStrategy", params)
        },
        issuedFile(params){  // 按用户下发文件
            return Ajax.ajaxs("/issue/issuedFile", params)
        },
        issuedApp(params){  // 按用户下发应用
            return Ajax.ajaxs("/issue/issuedApp", params)
        },
        issuedOrdinary(params){   // 下发普通命令
            return Ajax.ajaxs("/issue/issuedOrdinary", params)
        },
        list(params){  // 获取下发记录
            return Ajax.ajaxs("/issue/list", params)
        },
        delete(params){
            return Ajax.ajaxs("/issue/delete", params)
        },
        delStrategy(params){  // 按设备删除策略、应用、文件
            return Ajax.ajaxs("/issue/delStrategy", params)
        },
        exportExcel(params){   // 导出策略
            return Ajax.ajaxs("/issue/exportExcel", params, 'GET');
        },
    },
    logs: {
        adminOperList(params){   // 管理员操作日志
            return Ajax.ajaxs("/log/adminOperList", params)
        },
        userLogList(params){    // 用户登录日志
            return Ajax.ajaxs("/log/userLogList", params)
        },
        systemSwitchLogList(params){   // 系统切换日志
            return Ajax.ajaxs("/log/systemSwitchLogList", params)
        },
        missingComplianceLogList(params){  // 失联违规日志
            return Ajax.ajaxs("/log/missingComplianceLogList", params)
        },
        callReminderLogList(params){   // 来电提醒日志
            return Ajax.ajaxs("/log/callReminderLogList", params)
        },
        systemIllegalLogList(params){  // 系统违规日志
            return Ajax.ajaxs("/log/systemIllegalLogList", params)
        },
        appLogList(params){   // 应用违规日志
            return Ajax.ajaxs("/log/appLogList", params)
        },
        userTrackLogList(params){   // 用户行动轨迹
            return Ajax.ajaxs("/log/userTrackLogList", params)
        },
        userSoundLogList(params){   // 通话录音记录
            return Ajax.ajaxs("/log/userSoundLogList", params)
        },
        userSmsLogList(params){   // 短信记录
            return Ajax.ajaxs("/log/userSmsLogList", params)
        },
        debugLogList(params){   // 日志列表
            return Ajax.ajaxs("/log/debugLogList", params)
        },
        exportUserLog(params){   // 导出用户登录日志
            return Ajax.ajaxs("/log/exportUserLog", params)
        },
        exportSystemSwitchLog(params){   // 导出系统切换日志
            return Ajax.ajaxs("/log/exportSystemSwitchLog", params)
        },
        exportIllegalLog(params){   // 导出系统违规日志
            return Ajax.ajaxs("/log/exportIllegalLog", params)
        },
        exportAppLog(params){   // 导出应用违规日志
            return Ajax.ajaxs("/log/exportAppLog", params)
        },
        exportCallReminderLog(params){   // 导出来电提醒日志
            return Ajax.ajaxs("/log/exportCallReminderLog", params)
        },
        exportDebugLog(params){   // 导出调试日志
            return Ajax.ajaxs("/log/exportDebugLog", params)
        },
        exportMissingComplianceLog(params){   // 导出失联违规日志
            return Ajax.ajaxs("/log/exportMissingComplianceLog", params)
        },
        userTelebookLogList(params){   // 通讯录记录
            return Ajax.ajaxs("/log/queryPhoneLog", params)
        },
        userTelebookList(params){   // 设备管理-个人通讯录
            return Ajax.ajaxs("/log/queryPhoneLogList", params)
        },
        exportPhoneExcel(params){   // 导出个人通讯录
            return Ajax.ajaxs("/log/exportPhoneExcel", params, 'GET')
        },
        findFaceLog(params){  // 人脸区域管理-监控日志
            return Ajax.ajaxs("/log/findFaceLog", params)
        },
        queryEntranceGuardRegionLog(params){  // 人脸区域管理-监控日志
            return Ajax.ajaxs("/log/queryEntranceGuardRegionLog", params)
        },
        exportAdminOperLog(params){   // 导出平台操作日志
            return Ajax.ajaxs("/log/exportAdminOperLog", params)
        },
        feedBackList(params){   // 分页查询家长反馈
            return Ajax.ajaxs("/log/feedBackList", params)
        },
        trialList(params){   // 分页查询家长.学校预约
            return Ajax.ajaxs("/log/trialList", params)
        },
        exportTrial(params){   // 导出家长.学校预约
            return Ajax.ajaxs("/log/exportTrial", params)
        },
    },
    equipmentInfo: {
        add(params){  // 给用户添加绑定设备
            return Ajax.ajaxs("/equipmentInfo/add", params)
        },
        update(params){  // 修改绑定设备信息
            return Ajax.ajaxs("/equipmentInfo/update", params)
        },
        delete(params){  // 删除未激活设备
            return Ajax.ajaxs("/equipmentInfo/delete", params)
        },
        findByUserId(params){  // 获取用的所有设备列表
            return Ajax.ajaxs("/equipmentInfo/findByUserId", params)
        },
        addDeviceList(params){  // 批量导入设备信息
            return Ajax.ajaxs("/equipmentInfo/addDeviceList", params)
        },
        list(params){  // 获取设备列表
            return Ajax.ajaxs("/equipmentInfo/list", params)
        },
        findEquDetailByDeviceId(params){  // 设备详情-设备状态
            return Ajax.ajaxs("/equipmentInfo/findEquDetailByDeviceId", params)
        },
        listByDeviceId(params){  // 设备详情-文件
            return Ajax.ajaxs("/equipmentInfo/listByDeviceId", params)
        },
        exportExcel(params){    // 导出设备数据
            return Ajax.ajaxs("/equipmentInfo/exportExcel", params, 'GET');
        },
        listUserTrack(params){ // 获取设备运行轨迹
            return Ajax.ajaxs("/equipmentInfo/listUserTrack", params)
        },
        listTrackDates(params){  // 查询有轨迹的日期列表
            return Ajax.ajaxs("/equipmentInfo/listTrackDates", params)
        },
        listUserSms(params){  // 查询短信记录
            return Ajax.ajaxs("/equipmentInfo/listUserSms", params)
        },
        listUserSoundLog(params){  // 查询通话录音
            return Ajax.ajaxs("/equipmentInfo/listUserSoundLog", params)
        },
        getDeviceCoordinate(params){  // 查询设备最新定位
            return Ajax.ajaxs("/equipmentInfo/getDeviceCoordinate", params)
        },
        existName(params){  // 验证设备名称是否被占用
            return Ajax.ajaxs("/equipmentInfo/existName", params)
        },
        unlockEquopment(params){  // 解锁密码（设备解锁）
            return Ajax.ajaxs("/equipmentInfo/unlockEquopment", params)
        },
        listMbAppsByDeviceId(params){ // 设备详情--应用
            return Ajax.ajaxs("/equipmentInfo/listMbAppsByDeviceId", params)
        },
        listOperLogByDeviceId(params){ // 设备详情--设备操作日志
            return Ajax.ajaxs("/equipmentInfo/listOperLogByDeviceId", params)
        },
        getOperLogList(params){   // 客户端操作日志
            return Ajax.ajaxs("/equipmentInfo/getOperLogList", params)
        },
        exportOperLogExcel(params){   // 导出客户端操作日志
            return Ajax.ajaxs("/equipmentInfo/exportOperLogExcel", params, 'GET')
        },
        existDeviceName(params){  // 查询设备名称是否存在，返回不存在的名称，用户导入个人电话白名单时
            return Ajax.ajaxs("/equipmentInfo/existDeviceName", params)
        },
        existPhone(params){  // 校验孩子电话号码是否重复
            return Ajax.ajaxs("/equipmentInfo/existPhone", params)
        },
        existstudentPhone(params){  // 校验孩子电话号码是否重复
            return Ajax.ajaxs("/equipmentInfo/existDevicePhone", params)
        },
        existStudentNum(params){  // 校验学号是否重复
            return Ajax.ajaxs("/equipmentInfo/existStudentNum", params)
        },
        existDeviceFile(params){  // 根据文件名判断文件是否存在
            return Ajax.ajaxs("/equipmentInfo/existDeviceFile", params)
        },
        switchBinding(params){  // 切换家长
            return Ajax.ajaxs("/equipmentInfo/switchBinding", params)
        },
        issueInfoList(params){  // 下发记录
            return Ajax.ajaxs("/equipmentInfo/issueInfoList", params)
        },
    },
    position: {  // 职位管理
        list(params){
            return Ajax.ajaxs("/position/list", params)
        },
        add(params){
            return Ajax.ajaxs("/position/add", params)
        },
        update(params){
            return Ajax.ajaxs("/position/update", params)
        },
        delete(params){
            return Ajax.ajaxs("/position/delete", params)
        }
    },
    sysconfig: {
        list(params){ // 系统配置列表
            return Ajax.ajaxs("/sysconfig/list", params, 'GET')
        },
        sysGet(params){ // 根据ID查找一个系统配置
            return Ajax.ajaxs("/sysconfig/get", params)
        },
        update(params){ // 修改系统配置
            return Ajax.ajaxs("/sysconfig/update", params)
        },
    },
    phoneContact: {
        findPhoneContactByDeviceId(params){ // 个人通话白名单列表
            return Ajax.ajaxs("/phoneContact/findPhoneContactByDeviceId", params)
        },
        add(params){ // 新增个人通话白名单
            return Ajax.ajaxs("/phoneContact/add", params)
        },
        update(params){ // 修改个人通话白名单
            return Ajax.ajaxs("/phoneContact/update", params)
        },
        delete(params){ // 删除个人通话白名单
            return Ajax.ajaxs("/phoneContact/delete", params)
        },
        addPhoneList(params){ // 批量导入个人通话白名单
            return Ajax.ajaxs("/phoneContact/addPhoneList", params)
        },
        checkPhoneExists(params){ // 检查电话白名单是否重复
            return Ajax.ajaxs("/phoneContact/checkPhoneExists", params)
        },
    },
    face: {   
        findUser(params){  // 用户状态
            return Ajax.ajaxs("/face/findUser", params)
        },
        findCamera(params){  // 摄像头配置-查询
            return Ajax.ajaxs("/face/findCamera", params)
        },
        insertCamera(params){  // 摄像头配置-新增
            return Ajax.ajaxs("/face/insertCamera", params)
        },
        updateCamera(params){  // 摄像头配置-修改
            return Ajax.ajaxs("/face/updateCamera", params)
        },
        deleteCamera(params){  // 摄像头配置-删除
            return Ajax.ajaxs("/face/deleteCamera", params)
        },
        find(params){  // 区域配置-查询
            return Ajax.ajaxs("/face/find", params)
        },
        insert(params){  // 区域配置-新增
            return Ajax.ajaxs("/face/insert", params)
        },
        update(params){  // 区域配置-修改
            return Ajax.ajaxs("/face/update", params)
        },
        delete(params){  // 区域配置-删除
            return Ajax.ajaxs("/face/delete", params)
        },
        findState(params){  // 区域状态-查询
            return Ajax.ajaxs("/face/findState", params, 'GET')
        },
        queryAllCamera(params){  // 摄像头查询
            return Ajax.ajaxs("/face/queryAllCamera", params, 'GET')
        },
        headPhotoUpload(params){  // 用户状态-更换照片
            return Ajax.uploadFile("/face/headPhotoUpload", params)
        }
    },
    door: {
        findAll(params){  // 门禁区域配置列表查询
            return Ajax.ajaxs("/door/findAll", params, 'GET')
        },
        insert(params){  // 门禁区域配置-新增
            return Ajax.ajaxs("/door/insert", params)
        },
        update(params){  // 门禁区域配置-修改
            return Ajax.ajaxs("/door/update", params)
        },
        delete(params){  // 门禁区域配置-删除
            return Ajax.ajaxs("/door/delete", params)
        },
        queryState(params){  // 门禁状态列表查询
            return Ajax.ajaxs("/door/queryState", params, 'GET')
        },
    },
    command: {   // 命令
        list(params){  // 获取重发记录
            return Ajax.ajaxs("/command/list", params)
        },
        delete(params){   // 删除重发记录
            return Ajax.ajaxs("/command/delete", params)
        },
        getSystemLogs(params){   // 获取系统日志列表
            return Ajax.ajaxs("/command/getSystemLogs", params, 'GET')
        }
    },
    member: {
        orderList(params){  // 订单列表
            return Ajax.ajaxs("/member/orderList", params)
        },
        getMemberList(params){  // 查询会员信息列表
            return Ajax.ajaxs("/member/getMemberList", params)
        }
    },
    phoneProduct: {
        list(params) {  // 获取所有手机商品
            return Ajax.ajaxs("/phoneProduct/all", params)
        },
        add(params) {  // 新增手机商品
            return Ajax.uploadFile("/phoneProduct/add", params)
        },
        update(params) {  // 修改手机商品
            return Ajax.uploadFile("/phoneProduct/update", params)
        },
        delete(params) {  // 删除手机商品
            return Ajax.ajaxs("/phoneProduct/delete", params)
        }
    },
    phoneOrder: {
        list(params) {  // 分页查看手机订单
            return Ajax.ajaxs("/phoneOrder/list", params)
        },
    },
    agentStore: {
        list(params) {  // 查看代理商列表
            return Ajax.ajaxs("/agentStore/list", params, 'GET')
        },
        add(params) {  // 添加代理商
            return Ajax.ajaxs("/agentStore/add", params)
        },
        update(params) {  // 修改代理商
            return Ajax.ajaxs("/agentStore/update", params)
        },
        delete(params) {  // 删除代理商
            return Ajax.ajaxs("/agentStore/delete", params)
        },
    },
    agentRecord: {
        list(params) {  // 代理商业绩查询
            return Ajax.ajaxs("/AgentRecord/list", params, 'GET')
        }
    },
    childrenBindRecord: {
        list(params) {  // 孩子端绑定记录查询
            return Ajax.ajaxs("/childrenBindRecord/list", params, 'GET')
        }
    }
}