import {createStore} from 'redux'
import reducer from '../Reducer'
const initValue={
    positionList: [],  // 工作职位 列表
    logo: localStorage.getItem("logo"),
    background: localStorage.getItem("background"),
    'language': localStorage.getItem("locale") ? localStorage.getItem("locale") : navigator.language.split('-')[0],
    fresh: false
}
const store=createStore(reducer,initValue)
export default store