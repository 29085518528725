import React from 'react'
import CustomMenu from "../CustomMenu/index";
import API from "../../api/api.js";
import redux from "../../redux/Store"

class SiderNav extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      menus: []
    }
  }

  /**
   * 计算属性 获取logo
   */
  get logoUrl(){
    // 获取logo
    let logo = redux.getState().logo
    if(!logo || logo === "null"){
      logo = require("../../assets/img/logo3.png")
    }else{
      logo = window.constants.baseURL + logo;
    }
    return logo;
  }

  componentDidMount() {
    this.getMenuTree();
  }

  getfresh() {
    let fresh = redux.getState().fresh
    if (fresh) {
      this.getMenuTree();
    }
  }
  getMenuTree = async () => {
    // 获取菜单数据
    let res = await API.menu.list();
    this.setState({
      menus: res.content
    })
  }

  render() {

    return (
      <div style={{height: '100vh',overflowY:'auto',position: 'fixed'}}>
        <div className="logo_box" style={{width: !this.props.collapsed ? 180 : 'auto' }}>
          <img src={this.logoUrl} style={{marginRight: 6}} alt=""/>
          <span className="logo_title" style={{fontSize: 13}}>筑泰云宝管理平台</span>
        </div>
        <CustomMenu menus={this.state.menus}/>
        {/* 设置权限修改时 */}
        {this.fresh}
      </div>
    )
  }
}
export default SiderNav