import React from 'react'
import { Icon, Badge, Dropdown, Menu, Modal, Button, Input, Form, Table, message, Avatar, Pagination } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl';
import screenfull from 'screenfull'
import { inject, observer } from 'mobx-react'
import { Link, withRouter } from 'react-router-dom'
import { isAuthenticated } from '../../utils/Session'
import API from "../../api/api"
import redux from '../../redux/Store'
import Utils from "@/utils/utils.js"
import md5 from "js-md5"
const FormItem = Form.Item;

//withRouter一定要写在前面，不然路由变化不会反映到props中去
@withRouter @inject('appStore') @observer
@Form.create()
class HeaderBar extends React.Component {
  state = {
    icon: 'arrows-alt',
    count: 0,
    visible: false,
    avatar: require('./img/04.jpg'),
    modalVisible: false,
    modalTitle: '',
    msgVisible: false, // 已读/未读消息弹框
    loading: true,
    msgData: [], // 信息列表
    adminMsg: {}, // 管理员信息数据
    form: {},
    btnLoading: false,
    pageNo: 0,
    pageSize: 10,
    totalCount: 0,
  }

  // 计算属性 获取语言
  get locale(){
    return redux.getState().language
  }

  componentDidMount () {
    this.getFindAdmin()
    screenfull.onchange(() => {
      this.setState({
        icon: screenfull.isFullscreen ? 'shrink' : 'arrows-alt'
      })
    })
    // 未读消息个数
    this.getCountNoticeMessage()
  }
  // 未读消息个数
  getCountNoticeMessage = async () => {
    let res = await API.msg.countNoticeMessage()
    this.setState({
      count: res.content
    })
  } 

  // 获取用户基本信息
  getFindAdmin = async () => {
    // let res = await API.admin.findAdmin()
    let res = await JSON.parse(sessionStorage.getItem("adminInfo"))
    this.setState({adminMsg: res, form: res})
  }
  componentWillUnmount () {
    screenfull.off('change')
  }

  toggle = () => {
    this.props.onToggle()
  }
  screenfullToggle = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle()
    }
  }
  logout = async () => {
    // this.props.appStore.toggleLogin(false)
    // this.props.history.push(this.props.location.pathname)
    let res = await API.logout();
    if(res.code === 200){
      // 清除token
      sessionStorage.removeItem("token");
      this.props.appStore.toggleLogin(false);
      this.props.history.push("/page/login");
    }
  }
  // 语言切换
  languageChange = () => {
    let lang = this.locale === 'zh' ? 'en' : 'zh';
    this.setState({
      locale: lang
    })
    localStorage.setItem('locale', lang);
    redux.dispatch({ type: lang, payload: lang })
  }
  // 保存
  handleSubmit = (e) => {
    this.props.form.validateFields(async (err, values) => {
        if(!err){
          this.setState({btnLoading: true})
          if (this.state.modalTitle === 'ChangeBasicInformation') {
            let params = {
                id: this.state.form.id,
                ...values
            }
            console.log(params)
            await API.admin.update(params);
            // 国际化调API
            this.setState({btnLoading: false})
            const { intl: { formatMessage } } = this.props
            const successTips = formatMessage({id:'SuccessfulOperation'});
            message.success(successTips);
            // 重置表单数据
          } else {
            // 密码加密
            values.oldpwd = md5(values.oldpwd)
            values.newpwd = md5(values.newpwd)
            values.ConfirmPassword = md5(values.ConfirmPassword)
            let params = {
                id: this.state.form.id,
                ...values
            }
            console.log(params)
            await API.admin.updatePassword(params)
            // 国际化调API
            this.setState({btnLoading: false})
            const { intl: { formatMessage } } = this.props
            const successTips = formatMessage({id:'SuccessfulOperation'});
            message.success(successTips);
          }
          this.setState({
            modalVisible: false
          })
          this.logout()
        }
    });
  }

  async getRemoteData(){
    let res = await API.msg.findNoticeMessageList({
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize,
    });
    this.setState({
      msgVisible: true,
      loading: false,
      msgData: res.content,
      totalCount: res.totalElements
    })
  }
  // 打开消息弹框
  showCountModal = async () => {
    this.getRemoteData()
  }
  // 关闭消息弹框
  handleMsgCancel = async () => {
    this.setState({
      count: 0,
      msgVisible: false
    })
    await API.msg.updateNoReadMsgList();
  }
  // 打开弹框
  showModal = (e) => {
    let title = ''
    if (e === 'personal') {
      // 获取用户基本信息
      this.getFindAdmin()
      title = 'ChangeBasicInformation' 
    } else {
      title = 'ChangePassword'
    }
    this.setState({
      modalVisible: true,
      modalTitle: title
    })
  }
  /**
     * 点击取消按钮
     */
    handleCancel = (e) => {
      this.setState({
        modalVisible: false,
        btnLoading: false
      })
      this.props.form.resetFields()
  }
  /**
   * 验证确认密码是否正确
   */
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newpwd')) {
      callback(<FormattedMessage id="TwoPasswordDiff" />);
    } else {
      callback();
    }
  }
  // 验证旧密码
  checkOldPassword = (rule, value, callback) => {
    if (!(/^[A-Z]\w{7,}$/.test(value))) {
      callback(<FormattedMessage id="passwordRule" />);
    } else {
      callback();
    }
  }
  render () {
    const {icon, count, visible, avatar} = this.state
    const {appStore, collapsed, location} = this.props
    const notLogin = (
      <div>
        <Link to={{pathname: '/page/login', state: {from: location}}} style={{color: 'rgba(0, 0, 0, 0.65)'}}>登录</Link>&nbsp;
        <img src={require('../../assets/img/defaultUser.jpg')} alt=""/>
      </div>
    )
    const menu = (
      <Menu className='menu'>
        <Menu.ItemGroup title={(<FormattedMessage id="UserCenter" />)} className='menu-group'>
          <Menu.Item><FormattedMessage id="Hello" /> - {isAuthenticated()}</Menu.Item>
          {/* 账号资料 */}
          <Menu.Item><span onClick={() => this.showModal('personal')}><FormattedMessage id="AccountInfo"/></span></Menu.Item>
          {/* 修改密码 */}
          <Menu.Item><span onClick={() => this.showModal()}><FormattedMessage id="Modifypassword"/></span></Menu.Item>
          <Menu.Item><span onClick={this.logout}><FormattedMessage id="Logout" /></span></Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    )
    const login = (
      <Dropdown overlay={menu}>
        {/* <img src={avatar} alt=""/> */}
        <Avatar icon="user" />
      </Dropdown>
    )
    const { getFieldDecorator} = this.props.form;
    // 未读消息提醒
    const columns = [{
          title: (<FormattedMessage id="MessageContents" />),  // 消息内容
          width: 450,
          align: 'center',
          dataIndex: 'content'
      },{
          title: (<FormattedMessage id="CreationTime" />),  // 创建时间
          align: 'center',
          dataIndex: 'createtime'
      },{
        title: (<FormattedMessage id="State" />),  // 状态
        align: 'center',
        dataIndex: 'status',
        width: 100,
        render: (id, row, index) => (
          <div>  
            { row.status === 0 ? (<div style={{ color: 'red'}}><FormattedMessage id="UnRead"/></div>) : (<FormattedMessage id="Read"/>)}
          </div>
        )
      }]
    return (
      <div id='headerbar'>
        <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} className='trigger' onClick={this.toggle} style={{color: '#fff'}}/>
        <div style={{float: 'right'}}>
          <ul className='header-ul'>
            <li><Icon type={icon} onClick={this.screenfullToggle} style={{color: '#fff'}}/></li>
            <li>
              <Button size="small" onClick={this.languageChange}>{this.locale==="zh" ? "English": "中文"}</Button>
            </li>
            <li onClick={this.showCountModal}>
              <Badge count={appStore.isLogin ? count : 0} overflowCount={99} style={{marginLeft: 17}}>
                <Icon type="notification" style={{color: '#fff'}}/>
              </Badge>
            </li>
            <li>
              {appStore.isLogin ? login : notLogin}
            </li>
          </ul>
        </div>
        <Modal
          footer={null} closable={false}
          visible={visible}
          wrapClassName="vertical-center-modal"
          onCancel={() => this.setState({visible: false})}>
          <img src={avatar} alt="" width='100%'/>
        </Modal>
        {/* 修改资料/修改密码弹框 */}
        <Modal
          visible={this.state.modalVisible}
          title={(<FormattedMessage id={this.state.modalTitle} />)}
          onOk={this.handleSubmit}
          confirmLoading={this.state.btnLoading}
          onCancel={this.handleCancel}>
            <Form layout="horizontal" ref="myForm">
              {this.state.modalTitle === 'ChangeBasicInformation' ? (
                  <div>
                    <FormItem label={(<FormattedMessage id="Account" />)} {...window.constants.formItemLayout}>
                    {getFieldDecorator('loginName', {
                      initialValue: this.state.form.loginName
                    })(<Input disabled/>)}
                    </FormItem>
                    <FormItem label={(<FormattedMessage id="AdministratorsName" />)} {...window.constants.formItemLayout}>
                      {getFieldDecorator('realName', {
                          rules: [{ required: true, whitespace: true, message: (<FormattedMessage id="InputItem" values={{name: (<FormattedMessage id="AdministratorsName" />)}} />) }],
                          initialValue: this.state.form.realName
                      })(<Input maxLength={32}/>)}
                    </FormItem>
                    <FormItem label={(<FormattedMessage id="Mailbox" />)} {...window.constants.formItemLayout}>
                      {getFieldDecorator('email', {
                          // eslint-disable-next-line no-useless-escape
                          rules: [{pattern: /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/, message: (<FormattedMessage id="InputCorrect" values={{name: (<FormattedMessage id="Mailbox" />)}} />)}],
                          initialValue: this.state.form.email
                      })(<Input maxLength={32}/>)}
                    </FormItem>
                    <FormItem label={(<FormattedMessage id="PhoneNumber" />)} {...window.constants.formItemLayout}>
                      {getFieldDecorator('phoneNum', {
                          rules: [{pattern: /^(((13[0-9])|(14[5-7])|(15[0-9])|(17[0-9])|(18[0-9]))+\d{8})$/, message: (<FormattedMessage id="InputCorrect" values={{name: (<FormattedMessage id="MobileNumber" />)}} />)}],
                          initialValue: this.state.form.phoneNum
                      })(<Input maxLength={11}/>)}
                    </FormItem>
                  </div>
                ) : (
                  <div>
                    {/* 旧密码 */}
                    <FormItem label={(<FormattedMessage id="OldPassword" />)} {...window.constants.formItemLayout}>
                      {getFieldDecorator('oldpwd', {
                          rules: [{ required: true, whitespace: true, message: (<FormattedMessage id="InputItem" values={{name: (<FormattedMessage id="OldPassword" />)}} />) }],
                      })(
                        <Input type="password"/>
                      )}
                    </FormItem>
                    {/* 新密码 */}
                    <FormItem label={(<FormattedMessage id="NewPassword" />)} {...window.constants.formItemLayout}>
                      {getFieldDecorator('newpwd', {
                          rules: [{ required: true, whitespace: true, message: (<FormattedMessage id="InputItem" values={{name: (<FormattedMessage id="NewPassword" />)}} />) },
                          {pattern: /^[A-Z]\w{7,}$/, message: (<FormattedMessage id="passwordRule" />)}]
                      })(
                        <Input type="password"/>
                      )}
                    </FormItem>
                    {/* 确认密码 */}
                    <FormItem label={(<FormattedMessage id="ConfirmPassword" />)} {...window.constants.formItemLayout}>
                      {getFieldDecorator('ConfirmPassword', {
                           rules: [{ 
                            required: true, whitespace: true, message: (<FormattedMessage id="PleaseConfirmPassword" />) }, {
                            validator: this.compareToFirstPassword,
                        }],
                          initialValue: this.state.form ? this.state.form.confirmPassword : ''
                      })(
                        <Input type="password"/>
                      )}
                    </FormItem>
                  </div>
                )
              }
            </Form>
        </Modal>
        {/* 消息弹框 */}
        <Modal
          visible={this.state.msgVisible}
          title={(<FormattedMessage id='PlatformMessage' />)}
          onCancel={this.handleMsgCancel}
          width={1000}
          footer={[<Button key="back" type="primary" onClick={this.handleMsgCancel}><FormattedMessage id="OK"/></Button>]}>
          {/* 表格 */}
          <Table rowKey={record => record.id} loading={this.state.loading} dataSource={this.state.msgData} pagination={false} columns={ columns}/>
          <Pagination showSizeChanger pageSize={this.state.pageSize} current={this.state.pageNo+1} total={this.state.totalCount}
          onChange={(page, pageSize) => Utils.onPageChange(this, page, pageSize)} onShowSizeChange={(page, pageSize) => Utils.onPageChange(this, page, pageSize)} />
        </Modal>
      </div>
    )
  }
}

export default injectIntl(HeaderBar)