import React from 'react'
import BGParticle from '../../utils/BGParticle'
import { Form, Input, Row, Col, notification, Icon, Checkbox } from 'antd'
import './style.css'
import Utils from '../../utils/utils'
import PromptBox from '../../components/PromptBox'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import Loading2 from '../../components/Loading2'
import 'animate.css'
import API from "../../api/api"
import redux from "../../redux/Store"
import md5 from "js-md5"
const url = require("../../assets/img/bg.jpg")
const imgs = []
var st = null;

@withRouter @inject('appStore') @observer @Form.create()
class LoginForm extends React.Component {
	state = {
		focusItem: -1,   //保存当前聚焦的input
		code: '',         //验证码
		checked: localStorage.getItem('password') && localStorage.getItem('userName') ? true : false,
		codeImg: '',
		loginSessionId: '',
	}

	componentDidMount () {
		// this.createCode()
		this.createCodeBackEnd();

		st = setInterval(this.createCodeBackEnd, 1000*60*3);
	}

	componentWillUnmount () {
		clearInterval(st);
	}

	/**
	 * 生成验证码
	 */
	createCode = () => {
		const ctx = this.canvas.getContext('2d')
		const chars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
		let code = ''
		ctx.clearRect(0, 0, 80, 39)
		for (let i = 0; i < 4; i++) {
			const char = chars[Utils.randomNum(0, 57)]
			code += char
			ctx.font = Utils.randomNum(20, 25) + 'px SimHei'  //设置字体随机大小
			ctx.fillStyle = '#D3D7F7'
			ctx.textBaseline = 'middle'
			ctx.shadowOffsetX = Utils.randomNum(-3, 3)
			ctx.shadowOffsetY = Utils.randomNum(-3, 3)
			ctx.shadowBlur = Utils.randomNum(-3, 3)
			ctx.shadowColor = 'rgba(0, 0, 0, 0.3)'
			let x = 80 / 5 * (i + 1)
			let y = 39 / 2
			let deg = Utils.randomNum(-25, 25)
			/**设置旋转角度和坐标原点**/
			ctx.translate(x, y)
			ctx.rotate(deg * Math.PI / 180)
			ctx.fillText(char, 0, 0)
			/**恢复旋转角度和坐标原点**/
			ctx.rotate(-deg * Math.PI / 180)
			ctx.translate(-x, -y)
		}
		this.setState({
			code
		})
	}

	createCodeBackEnd = async () => {
		let res = await API.admin.verifyCode();
		if(res.content) {
			this.setState({
				codeImg: res.content.base64,
				loginSessionId: res.content.id
			})
		}
	}

	// 登录
	loginSubmit = (e) => {
		e.preventDefault()
		this.setState({
			focusItem: -1
		})
		this.props.form.validateFields(async (err, values) => {
			if (!err) {
				// 密码加密
				values.password = md5(values.password)
				let res = await API.login({
					...values,
					verifyId: this.state.loginSessionId
				});
				// 获取并保存token
				let token = res.content.token;
				sessionStorage.setItem("token", token)
				this.props.appStore.toggleLogin(true, {username: values.userName})

				// 获取用户基本信息
				this.getFindAdmin();

				// 获取LOGO和背景图片
				let res2 = await API.plat.findLogo();
				if(res2.content){
					localStorage.setItem("logo", res2.content.logo);
					localStorage.setItem("background", res2.content.backgroundPicture);
					redux.dispatch({ type: res2.content.logo, payload: "logo" });
					redux.dispatch({ type: res2.content.backgroundPicture, payload: "background" });
				}
				const {from} = this.props.location.state || {from: {pathname: '/'}}
				this.props.history.push(from)
			}
		})
	}

	// 获取用户基本信息
	getFindAdmin = async () => {
		let res = await API.admin.findAdmin()
		sessionStorage.setItem("adminInfo", JSON.stringify(res.content))
	}

	/**
	 * 记住账号和密码
	 */
	RemenberLoginMsg = (checkedValue) => {
		const { getFieldValue } = this.props.form
		this.setState({checked: checkedValue.target.checked})
		if (checkedValue.target.checked) {
			localStorage.setItem('userName', getFieldValue('userName'))
			localStorage.setItem('password', getFieldValue('password'))
		} else {
			localStorage.removeItem('userName')
			localStorage.removeItem('password')
		}
	}

	render () {
		const {getFieldDecorator, getFieldError} = this.props.form
		const {focusItem, code, checked} = this.state
		return (
		<div className={this.props.className}>
			<h3 className='title'>管理员登录</h3>
			<Form onSubmit={this.loginSubmit}>
				<Form.Item help={getFieldError('userName') &&
				<PromptBox info={getFieldError('userName')} width={Utils.calculateWidth(getFieldError('userName'))}/>}>
					{getFieldDecorator('userName', {
					initialValue: localStorage.getItem('userName') ? localStorage.getItem('userName') : '',
					rules: [{required: true, whitespace: true, message: '请输入用户名'}]
					})(
					<Input
						onFocus={() => this.setState({focusItem: 0})}
						onBlur={() => {this.setState({focusItem: -1});}}
						onChange={(e) => {
						if (e.target.value !== localStorage.getItem('userName')) {
							this.setState({checked: false})
							localStorage.removeItem('userName')
						}
						}}
						maxLength={16}
						placeholder='用户名'
						addonBefore={<Icon type="user" style={focusItem === 1 ? styles.focus : {}}/>}/>
					)}
				</Form.Item>
				<Form.Item help={getFieldError('password') &&
				<PromptBox info={getFieldError('password')} width={Utils.calculateWidth(getFieldError('password'))}/>}>
					{getFieldDecorator('password', {
					initialValue: localStorage.getItem('password') ? localStorage.getItem('password') : '',
					rules: [{required: true, whitespace: true, message: '请输入密码'}]
					})(
					<Input
						onFocus={() => this.setState({focusItem: 1})}
						onBlur={() => {this.setState({focusItem: -1});}}
						onChange={(e) => {
						if (e.target.value !== localStorage.getItem('password')) {
							this.setState({checked: false})
							localStorage.removeItem('password')
						}
						}}
						type='password'
						maxLength={16}
						placeholder='密码'
						addonBefore={<Icon type="lock" style={focusItem === 1 ? styles.focus : {}}/>}/>
					)}
				</Form.Item>
				<Form.Item help={getFieldError('verifyCode') &&
				<PromptBox info={getFieldError('verifyCode')} width={Utils.calculateWidth(getFieldError('verifyCode'))}/>}>
					{getFieldDecorator('verifyCode', {
					validateFirst: true,
					rules: [
						{required: true, whitespace: true, message: '请输入验证码'},
						// 前端生成验证码校验用
						// {
						// validator: (rule, value, callback) => {
						// 	if (code.toUpperCase() !== value.toUpperCase()) {
						// 	callback('验证码错误')
						// 	}else{
						// 	callback()
						// 	}
						// }
						// }
					]
					})(
					<Row>
						<Col span={15}>
						<Input
							onFocus={() => this.setState({focusItem: 2})}
							onBlur={() => this.setState({focusItem: -1})}
							maxLength={4}
							placeholder='验证码'
							addonBefore={<Icon type="safety" style={focusItem === 2 ? styles.focus : {}}/>}/>
						</Col>
						<Col span={9} style={{ height: '39px'}}>
						{/* <canvas onClick={this.createCode} width="80" height='39' ref={el => this.canvas = el}/> */}
						<img alt="验证码" src={this.state.codeImg} onClick={ this.createCodeBackEnd } style={{cursor: 'pointer'}}></img>
						</Col>
					</Row>
					)}
				</Form.Item>
				<div>
					<Checkbox checked={checked} className="loginCheck" onChange={this.RemenberLoginMsg} >记住账号和密码</Checkbox>
				</div>
				<div className='bottom'>
					<input className='loginBtn' type="submit" value='登录'/>
				</div>
			</Form>
			<div className='footer'>
				<div>欢迎登录后台管理系统</div>
			</div>
		</div>
		)
	}
}

@withRouter @inject('appStore') @observer
class Login extends React.Component {
	state = {
		showBox: 'login',   //展示当前表单
		url: '',  //背景图片
		loading:false,
		loading2:false,
	}

	get background(){
		let background = redux.getState().background

		if(!background || background === "null"){
		background = require("../../assets/img/bg.jpg")
		}else{
		background = window.constants.baseURL + background.replace(/\\/g, "/")
		}
		return background
	}

	componentDidMount () {
		this.initPage()
		Utils.preloadingImages(imgs)  //预加载下一个页面的图片，预加载了第二次为什么还会去请求图片资源？
	}

	componentWillUnmount () {
		this.particle.destory()
		notification.destroy()
	}

	//载入页面时的一些处理
	initPage = () => {
		this.setState({
			loading:true
		})
		this.loadImageAsync(url).then(url=>{
			this.setState({
				loading:false,
				url
			})
		}).then(()=>{
			//为什么写在then里？id为backgroundBox的DOM元素是在loading为false时才有，而上面的setState可能是异步的，必须等到setState执行完成后才去获取dom
			this.particle = new BGParticle('backgroundBox')
			this.particle.init()
		})
	}

	//切换showbox
	switchShowBox = (box) => {
		this.setState({
			showBox: box
		})
	}

	//登录的背景图太大，等载入完后再显示，实际上是图片预加载，
	loadImageAsync (url) {
		return new Promise(function(resolve, reject) {
			const image = new Image();
			image.onload = function() {
				resolve(url);
			};
			image.onerror = function() {
				console.log('图片载入错误')
			};
			image.src = url;
		});
	}

	render () {
		const {showBox,loading} = this.state
		return (
		<div id='login-page'>
			{
			loading ?
				<div>
					<h3 style={styles.loadingTitle} className='animated bounceInLeft'>载入中...</h3>
					<Loading2/>
				</div>:
				<div>
					<div id='backgroundBox' style={{...styles.backgroundBox, backgroundImage: `url(${this.background})`}}></div>
					<div className='container'>
						<LoginForm
						className={showBox === 'login' ? 'box showBox' : 'box hiddenBox'}
						switchShowBox={this.switchShowBox}/>
					</div>
				</div>
			}
		</div>
		)
	}
}

const styles = {
	backgroundBox: {
		position: 'fixed',
		top: '0',
		left: '0',
		width: '100vw',
		height: '100vh',
		backgroundSize: '100% 100%',
		transition:'all .5s'
	},
	focus: {
		width: '20px',
		opacity: 1
	},
	loadingBox:{
		position:'fixed',
		top:'50%',
		left:'50%',
		transform:'translate(-50%,-50%)'
	},
	loadingTitle:{
		position:'fixed',
		top:'50%',
		left:'50%',
		marginLeft: -45,
		marginTop: -18,
		color:'#000',
		fontWeight:500,
		fontSize:24
	},
}

export default Login
