import React from "react"
import CustomBreadcrumb from '../../../../components/CustomBreadcrumb/index'
import { FormattedMessage, injectIntl } from 'react-intl';
import API from "../../../../api/api.js";
import {Card, Button, message, Form, Row, Col } from 'antd'

const FormItem = Form.Item;
@Form.create()
class Index extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            licenseModel: {},  // license信息
            currUserAmount: 0, // 当前用户数量
        }
    }

    componentDidMount() {
        this.getRemoteData()
    }

    getRemoteData = async () => {
        let res = await API.lic.get();
        this.setState({
            licenseModel: res.content.licenseModel ? res.content.licenseModel : {},
            currUserAmount: res.content.currUserAmount
        })
    }

    /**
     * License文件上传
     */
    fileChange = async (e) => {
        // 国际化调API
        const { intl: { formatMessage } } = this.props
        var file = e.target.files[0];
        if(file.name !== "license.lic"){
            message.error(formatMessage({id:'LicenseIncorrect'}));
            return;
        }

        var formData = new FormData();
        formData.append('file', file);
        await API.lic.add(formData);
        message.success(formatMessage({id:'SuccessfulOperation'}));
        this.getRemoteData();
    }
    render() {
        return (
            <div>
                {/* 面包屑导航 设置 、平台管理 、 授权许可 */}
                {
                    this.props.location.pathname === "/license" ? null : <CustomBreadcrumb arr={['Settings', 'ManagementPlatform', 'AuthorizationPermission']}/>
                }
                <Card bordered={false} style={{marginBottom: 10, minHeight: 762}}>
                    <Row>
                        <Col span={6} offset={5}>
                            {/* 上传License文件 */}
                            {/* <Upload {...props}>
                                <Button><Icon type="upload" /> <FormattedMessage id="UploadLicense" /></Button>
                            </Upload> */}
                            <Button type="primary" icon={'upload'}>
                                <FormattedMessage id="UploadLicense" />
                                <input type="file" className="file_input" ref="myFile" accept=".lic" onChange={this.fileChange} />
                            </Button>
                            {/* 返回登录 */}
                            {
                                this.props.location.pathname === "/license" ? 
                                <Button href="/page/login">
                                    <FormattedMessage id="ReturnToLogin" />
                                </Button> : null
                            }
                            
                        </Col>
                    </Row>
                    {/* License授权信息 */}
                    <Card style={{ marginTop: 16 }} type="inner" title={(<FormattedMessage id="LicenseInfo" />)}>
                        {/* 客户名称 */}
                        <FormItem label={(<FormattedMessage id="CustomerName" />)} {...window.constants.formItemLayout}>
                            <span className="ant-form-text">{this.state.licenseModel.customerName}</span>
                        </FormItem>
                        {/* 模块 */}
                        {/* <FormItem label={(<FormattedMessage id="Template" />)} {...window.constants.formItemLayout}>
                            <span className="ant-form-text"></span>
                        </FormItem> */}
                        {/* License类型 */}
                        <FormItem label={(<FormattedMessage id="LicenseType" />)} {...window.constants.formItemLayout}>
                            <span className="ant-form-text">
                            {   
                                // 正式使用 / 试用
                                this.state.licenseModel.licenseType ? (<FormattedMessage id="OfficialUse" />) : (<FormattedMessage id="OnTrial" />)
                            }
                            </span>
                        </FormItem>
                        {/* 可使用日期 */}
                        <FormItem label={(<FormattedMessage id="AvailableDate" />)} {...window.constants.formItemLayout}>
                            <span className="ant-form-text">{this.state.licenseModel.notAfter}</span>
                        </FormItem>
                        {/* 升级日期 */}
                        <FormItem label={(<FormattedMessage id="UpdateDate" />)} {...window.constants.formItemLayout}>
                            <span className="ant-form-text">{this.state.licenseModel.notBefore}</span>
                        </FormItem>
                        {/* License数量 */}
                        <FormItem label={(<FormattedMessage id="NumberLicenses" />)} {...window.constants.formItemLayout}>
                            <span className="ant-form-text">{this.state.licenseModel.userAmount}</span>
                        </FormItem>
                        {/* 已使用License数量 */}
                        <FormItem label={(<FormattedMessage id="NumberUsedLicenses" />)} {...window.constants.formItemLayout}>
                            <span className="ant-form-text">{this.state.currUserAmount}</span>
                        </FormItem>
                    </Card>
                </Card>
            </div>
        )
    }
}

export default injectIntl(Index)