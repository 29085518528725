import qs from 'qs';
import axios from "axios";
import { message } from "antd";
import appStore from "../store/appStore"
import moment from 'moment';
const timeFormat = 'HH:mm';
const dateFormat = 'YYYY-MM-DD';

const booleanToInt = (obj) => {
  if(!obj || typeof obj !== "object"){
    return obj
  }
  Object.keys(obj).forEach(key => {
    // 将true和false 转换成1和0
    if(obj[key] === "true" || obj[key] === true){
      obj[key] = 1;
    }else if(obj[key] === "false" || obj[key] === false){
      obj[key] = 0;
    }else if(typeof obj[key] === "object"){
      obj[key] = booleanToInt(obj[key])
    }
  })
  return obj;
}

// 请求拦截
axios.interceptors.request.use(
  config => {
    // 从cookie中获取token
    let token = sessionStorage.getItem("token");
    config.headers.Authorization = token;
    if(config.headers["Content-Type"] !== "multipart/form-data"){
      let data = qs.parse(config.data);
      // Object.keys(data).forEach(key => {
      //   // 将true和false 转换成1和0
      //   if(data[key] === "true"){
      //     data[key] = 1;
      //   }else if(data[key] === "false"){
      //     data[key] = 0;
      //   }
      // });
      // 将true和false 转换成1和0
      data = booleanToInt(data)

      // 处理时间围栏数据的封装
      if(data.strategyContent){
          let values = JSON.parse(data.strategyContent);
          // 将true和false 转换成1和0
          values = booleanToInt(values)
          // 时间范围
          if( values.timeRange && values.timeRange.length === 2){
            let timeRange ={};
            timeRange.startDate = moment(values.timeRange[0]).format(dateFormat);
            timeRange.endDate = moment(values.timeRange[1]).format(dateFormat);
            delete values.timeRange
            values.timeRange = timeRange
          }
          // 时间单元 
          if(values.timeUnit){
            values.timeUnit = values.timeUnit.map(item => {
                // 特殊时间
                if(item.unitType === 4){
                    item.typeDate = moment(item.typeDate).format(dateFormat);
                }
                // 时间单元
                item.startTime = moment(item.startTime).format(timeFormat);
                item.endTime = moment(item.endTime).format(timeFormat);
                return item;
            })
          }
          if(values.timeRange && values.timeUnit){
            // 封装
            values.timeData = {
              timeUnit: values.timeUnit,
              timeRange: values.timeRange
            }
            delete values.timeUnit
            delete values.timeRange
          }
          
          data.strategyContent = JSON.stringify(values);
        }
        config.data = qs.stringify(data);
      }
    
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// 拦截响应response，并做一些错误处理
axios.interceptors.response.use(
  response => {
    // 时间围栏数据，响应处理
    // if(response.request.responseURL && response.request.responseURL.indexOf("/strategy/list") !== -1){
    //   if(response.data.code === 200 && response.data.content){
    //     let res = response.data.content;
    //     response.data.content = res.map(item => {
    //       if(item.strategyContent){
    //         let values = JSON.parse(item.strategyContent);
    //         if(values.timeData){
    //           values = {...values, ...values.timeData};
    //           delete values.timeData;
    //         }
    //         item.strategyContent = values;
    //       }
    //       return item;
    //     })
    //   }
    // }
    
    return response;
  },
  err => {
    // 这里是返回状态码不为200时候的错误处理
    if (err && err.response) {
        message.error(err.message);
    }
    return Promise.reject(err);
  }
);

export default {
  baseURL: "/api",
  ajaxs(getUrl, params, methods) {
    return new Promise((resolve, reject) => {
      let data = Object.assign({}, params);
      let axiosParams = {
        url: this.baseURL + getUrl,
        method: methods ? methods : "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
      //添加参数
      if (methods === 'GET') {
        Object.defineProperty(axiosParams, 'params', {
            value: data,
            enumerable: true
        })
      } else {
        
          Object.defineProperty(axiosParams, 'data', {
              value: qs.stringify(data),
              enumerable: true
          })
      }
      axios(axiosParams).then(
          res => {
            if (res.data.code === 200) {
              resolve(res.data);
            }else if(res.data.code === 300){
              // 退出登录状态
              appStore.toggleLogin(false);
              window.location = "/page/login";
              reject(res.data);
            }else if(res.data.code === -4){
              message.error(res.data.msg);
              // 去上传license
              window.location = "/page/license";
              reject(res.data);
            } else {
              message.error(res.data.msg);
              reject(res.data);
            }
          },
          err => {
            reject(err);
          }
        )
        .catch(err => {
          reject(new Error(err));
        });
    });
  },
  //图片上传
  uploadFile(getUrl, params) {
    return new Promise((resolve, reject) => {
      // let param = new FormData(); // 创建form对象
      // param.append('file', params);
      console.log(params);
      axios({
        url: this.baseURL + getUrl,
        data: params,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(
          res => {
            if (res.data.code === 200) {
              resolve(res.data);
            } else {
              message.error(res.data.msg);
              reject(res.data);
            }
          },
          err => {
            reject(err);
          }
        )
        .catch(err => {
          throw new Error(err);
        });
    });
  }
};
