import React from 'react'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import LoadableComponent from '../../utils/LoadableComponent'
import PrivateRoute from '../PrivateRoute'

const Home = LoadableComponent(()=>import('../../routes/Home/index'))  //参数一定要是函数，否则不会懒加载，只会代码拆分

// 首页
const HomePage = LoadableComponent(() => import("../../routes/HomePage/index"))
// 学习软件
const ApplyManage = LoadableComponent(() => import("../../routes/Apply/index"))
// 学习资料
const FileManage = LoadableComponent(() => import("../../routes/File/index"))
// 孩子管理
const StudentManage = LoadableComponent(() => import("../../routes/Student/index"))
// 家长管理
const ParentManage = LoadableComponent(() => import("../../routes/Parent/index"))
// 订单管理
const OrderManage = LoadableComponent(() => import("../../routes/Order/index"))
// 会员管理
const MemberManage = LoadableComponent(() => import("../../routes/Member/index"))

// 用户管理 - 角色管理
const EducationJob = LoadableComponent(() => import('../../routes/Education/Job'))
// 用户管理 - 账号管理
const EducationTeacher = LoadableComponent(() => import("../../routes/Education/Teacher"))

// 设置 - 意见反馈
const ParentFeedback = LoadableComponent(() => import("../../routes/SetUp/ParentManage/Feedback"))
// 设置 - 菜单管理
const MenuManage = LoadableComponent(() => import('../../routes/SetUp/MenuManage/index'))
// 设置 - 平台设置 - 基本设置
const PlatformBasic = LoadableComponent(() => import("../../routes/SetUp/PlatformManage/Basic"))
// 设置 - 平台设置 - 授权许可
const PlatformLicense = LoadableComponent(() => import("../../routes/SetUp/PlatformManage/License"))
// 设置 - 平台管理 - 个性化设置
const PlatformPersonality = LoadableComponent(() => import("../../routes/SetUp/PlatformManage/Personality"))
// 设置 - 平台管理 - 系统配置
const PlatformSystem = LoadableComponent(() => import("../../routes/SetUp/PlatformManage/System/index"))
// 设置 - 平台管理 - 激活邮件模板设置
const PlatFromMail = LoadableComponent(() => import("../../routes/SetUp/PlatformManage/Mail"))
// 设置 - 客户端设置 - 基本设置
const ClientBasic = LoadableComponent(() => import("../../routes/SetUp/ClientManage/Basic"))
// 设置 - 客户端设置 - 版本更新
const ClientUpgrade = LoadableComponent(() => import("../../routes/SetUp/ClientManage/Upgrade"))
// 设置 - 客户端设置 - 机型管理
const ClientModel = LoadableComponent(() => import("../../routes/SetUp/ClientManage/Model"))
// 设置 - 客户端设置 - 操作系统管理
const ClientSystem = LoadableComponent(() => import("../../routes/SetUp/ClientManage/System"))
// 设置 - 关于平台
const About = LoadableComponent(() => import("../../routes/SetUp/About"))
// 设置 - 家长端设置 - 版本更新
const ParentUpgrade = LoadableComponent(() => import("../../routes/SetUp/ParentManage/Upgrade"))

// 日志报表 - 管理员操作日志-平台操作日志
const PlatformOperation = LoadableComponent(() => import("../../routes/Logs/PlatformOperation"))
// 日志报表 - 管理员操作日志-客户端操作日志
const ClientOperation = LoadableComponent(() => import("../../routes/Logs/ClientOperation"))
// 日志报表 - 用户操作日志 - 用户登录日志
const UserLoginLog = LoadableComponent(() => import("../../routes/Logs/User/UserLoginLog"))
// 日志报表 - 用户操作日志 - 系统切换日志
const SystemSwitch = LoadableComponent(() => import("../../routes/Logs/User/SystemSwitch"))
// 日志报表 - 用户操作日志 - 系统违规日志
const SystemViolation = LoadableComponent(() => import("../../routes/Logs/User/SystemViolation"))
// 日志报表 - 用户操作日志 - 失联违规日志
const LossViolation = LoadableComponent(() => import("../../routes/Logs/User/LossViolation"))
// 日志报表 - 用户操作日志 - 应用违规日志
const ApplyViolation = LoadableComponent(() => import("../../routes/Logs/User/ApplyViolation"))
// 日志报表 - 用户操作日志 - 来电提醒日志
const Call = LoadableComponent(() => import("../../routes/Logs/User/Call"))
// 日志报表 - 用户隐私日志 - 用户行动轨迹
const Track = LoadableComponent(() => import("../../routes/Logs/Privacy/Track"))
// 日志报表 - 用户隐私日志 - 通话录音记录
const PrivacyCall = LoadableComponent(() => import("../../routes/Logs/Privacy/Call"))
// 日志报表 - 用户隐私日志 - 短信记录
const SMS = LoadableComponent(() => import("../../routes/Logs/Privacy/SMS"))
// 日志报表 - 用户隐私日志 - 通讯录记录
const TeleBook = LoadableComponent(() => import('../../routes/Logs/Privacy/TeleBook'))
// 日志报表 - 调试日志
const Debug = LoadableComponent(() => import("../../routes/Logs/Debug"))
// 日志报表 - 重发记录日志
const ResendLogs = LoadableComponent(() => import("@/routes/Logs/Resend"))
// 日志报表 - 系统日志
const SystemLogs = LoadableComponent(() => import("@/routes/Logs/System"))

// 商城管理 - 商品管理
const MallProduct = LoadableComponent(() => import("@/routes/Mall/Product"))
// 商城管理 - 订单管理
const MallOrder = LoadableComponent(() => import("@/routes/Mall/Order"))

// 代理商管理 - 列表
const AgentList = LoadableComponent(() => import("@/routes/Agent/List"))
// 代理商管理 - 推广记录
const AgentRecord = LoadableComponent(() => import("@/routes/Agent/Record"))

@withRouter
class ContentMain extends React.Component {
  render () {
    return (
      <div style={{padding: 16, position: 'relative', minWidth: 1000}}>
        <Switch>
          <PrivateRoute exact path='/page/home' component={Home}/>
          <PrivateRoute exact path='/page/home/index' component={HomePage}/>
          <PrivateRoute exact path='/page/home/about' component={About}/>
          <PrivateRoute exact path='/page/home/parent' component={ParentManage}/>
          <PrivateRoute exact path='/page/home/education/job' component={EducationJob}/>
          <PrivateRoute exact path='/page/home/education/teacher' component={EducationTeacher}/>
          <PrivateRoute exact path='/page/home/student' component={StudentManage}/>
          <PrivateRoute exact path='/page/home/file' component={FileManage}/>
          <PrivateRoute exact path='/page/home/apply' component={ApplyManage}/>
          <PrivateRoute exact path='/page/home/setup/menuManage' component={MenuManage}/>
          <PrivateRoute exact path='/page/home/setup/platform/basic' component={PlatformBasic}/>
          <PrivateRoute exact path='/page/home/setup/platform/license' component={PlatformLicense}/>
          <PrivateRoute exact path='/page/home/setup/platform/personality' component={PlatformPersonality}/>
          <PrivateRoute exact path='/page/home/setup/platform/system' component={PlatformSystem}/>
          <PrivateRoute exact path='/page/home/setup/platform/mail' component={PlatFromMail}/>
          <PrivateRoute exact path='/page/home/setup/client/basic' component={ClientBasic}/>
          <PrivateRoute exact path='/page/home/setup/client/upgrade' component={ClientUpgrade}/>
          <PrivateRoute exact path='/page/home/setup/about' component={About}/>
          <PrivateRoute exact path='/page/home/setup/client/model' component={ClientModel}/>
          <PrivateRoute exact path='/page/home/setup/client/system' component={ClientSystem}/>
          <PrivateRoute exact path='/page/home/logs/platform' component={PlatformOperation}/>
          <PrivateRoute exact path='/page/home/logs/client' component={ClientOperation}/>
          <PrivateRoute exact path='/page/home/logs/user/userLogin' component={UserLoginLog}/>
          <PrivateRoute exact path='/page/home/logs/user/systemSwich' component={SystemSwitch}/>
          <PrivateRoute exact path='/page/home/logs/user/systemViolation' component={SystemViolation}/>
          <PrivateRoute exact path='/page/home/logs/user/applyViolation' component={ApplyViolation}/>
          <PrivateRoute exact path='/page/home/logs/user/lossViolation' component={LossViolation}/>
          <PrivateRoute exact path='/page/home/logs/user/call' component={Call}/>
          <PrivateRoute exact path='/page/home/logs/debug' component={Debug}/>
          <PrivateRoute exact path='/page/home/logs/privacy/track' component={Track}/>
          <PrivateRoute exact path='/page/home/logs/privacy/call' component={PrivacyCall}/>
          <PrivateRoute exact path='/page/home/logs/privacy/sms' component={SMS}/>
          <PrivateRoute exact path='/page/home/logs/privacy/telebook' component={TeleBook}/>
          <PrivateRoute exact path='/page/home/logs/resend' component={ResendLogs}/>
          <PrivateRoute exact path='/page/home/logs/system' component={SystemLogs}/>
          <PrivateRoute exact path='/page/home/setup/parent/feedback' component={ParentFeedback}/>
          <PrivateRoute exact path='/page/home/setup/parent/upgrade' component={ParentUpgrade}/>
          <PrivateRoute exact path='/page/home/order' component={OrderManage}/>
          <PrivateRoute exact path='/page/home/member' component={MemberManage}/>
          <PrivateRoute exact path='/page/home/mall/product' component={MallProduct}/>
          <PrivateRoute exact path='/page/home/mall/order' component={MallOrder}/>
          <PrivateRoute exact path='/page/home/agent/list' component={AgentList}/>
          <PrivateRoute exact path='/page/home/agent/record' component={AgentRecord}/>

          <Redirect exact from='/' to='/page/home/index'/>
        </Switch>
      </div>
    )
  }
}

export default ContentMain