// 多个地方会使用到的全局变量
window.constants = {
    baseURL: process.env.NODE_ENV === "development" ? "/api" : "",
    mapKey: "73ab4b540a30a9d4cab91fab5450e207",
    formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 19 }
    },
    command: { // 命令码：命令描述（中英文翻译名称）注意：110025已经被占用，SEND_USER_INFO(110025, 1, "下发用户信息")
        PlayRingtone: 110001,  // 播放铃声
        LocateDevice: 110002,  // 设备定位
        // EnableCallWhiteList: 110003, // 开启电话白名单
        // DisableCallWhiteList: 110004, // 关闭电话白名单
        // UpdateTelephoneBook: 110005, // 更新电话本
        LockDevice: 110003, // 设备锁定
        UnlockDevice: 110004, // 设备解锁
        // DeviceShutdown: 110008, // 设备关机
        ReleaseControl: 110022, // 解除管控
        // ObsoleteDevice: 110023, // 设备淘汰
        // SwitchToLifeDomain: 110011, // 切换至生活域
        // SwitchToSecurityDomain: 110012, // 切换至安全域
        // DeviceReset: 110013, // 设备重启
        // SendMessage: 110014,  // 发送消息
        // SetFlow: 110015,  // 设置流量
        GetDeviceDetails: 110005, // 获取设备详情
        LogUploading: 110006, // 日志上传
        DeviceUpdate: 110007, // 设备更新
        // AccessToTeleBook: 110024, // 实时获取通讯录
        IssueApplication: 130001, // 下发应用
        UninstallApplication: 1300020, // 卸载应用
        IssueFile: 140001, // 下发文件
        DeleteFiles: 1400020, // 删除文件
        "110016": "删除用户",
        "110017": "获取设备信息",
        "110020": "删除应用",
        "110021": "获取设置内容",
        "110022": "进入安全区域",
        "110023": "进入生活区域",
        "140003": "更新头像",
    },
    policyCommandType: [{
        type: "学习模式",
        issuedCommand: "110024",
        unIssuedCommand: "110025",
        typeCode: "1",
        commandDesc: "StudyMode" // 下发学习模式
    }]
}
