import React from 'react'
import {Breadcrumb} from 'antd'
import {Link} from 'react-router-dom'
import { FormattedMessage } from 'react-intl';

const CustomBreadcrumb = (props)=>(
  <Breadcrumb style={{marginBottom:16}}>
    {/* <Breadcrumb.Item>
    <Link to='/home'>
    <FormattedMessage id="HomePage" />
    </Link>
    </Breadcrumb.Item> */}
    {props.arr && props.arr.map(item=>{
      if ((typeof item) === 'object'){
        return <Breadcrumb.Item key={item.title}><Link to={item.to}>
        <FormattedMessage id={item.title} />
        </Link></Breadcrumb.Item>
      } else {
        return <Breadcrumb.Item key={item}>
        <FormattedMessage id={item} />
        </Breadcrumb.Item>
      }
    })}
  </Breadcrumb>
)
export default CustomBreadcrumb